import { MetaData, EthiClaims, DataUploaded, UserToken, DatesUploaded, GraphsCached, UserInfo, UserEmail, LiveConnections, Promises, Data, IsDev, Version } from "$lib/store.js";
import { bytesToBase64, base64ToBytes } from '$lib/b64.js'
import jwt_decode from "jwt-decode";
import pako from "pako";
import dayjs from "dayjs";
import { clearStores, writeFirebaseDoc, readFirebaseDoc } from "$lib/authapi.js";
import { get } from 'svelte/store';
import { compress } from "../components/upload/processors/encryptionProcessor.ts";
import { b64, base64encode } from "../components/upload/stores/util.ts";
import { goto } from "$app/navigation";


export let serverURL = typeof window !== 'undefined' ?
  `${process.env.SERVER_URL || "http://" + window.location.hostname + ":5000"}`
  :
  "nobrowser";
// typeof window !== 'undefined' ? window.isdev = process.env.DEV_DATABASE : "";
// staging: "https://flask-server-rya4g2ukoq-ez.a.run.app"
// prod: https://flask-server-ulyvmgpd2q-ez.a.run.app

export const DAY_SECS = 24 * 60 * 60;
export const DAY = DAY_SECS * 1000;
export const MONTH = 30 * DAY;
export const YEAR = 12 * MONTH;
let omnipilot;
(async function asdf() {
  omnipilot = typeof window != "undefined" ? window.location.href.includes('omnipilot') || (await window.api?.version)?.app == 'omnipilot' : ""
})()

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function currentTime() {
  // console.log("currentTime")
  // Return currentTime in secs since Jan 01 1970
  return Date.now() / 1000;
}

// Create a string representation of the date.
export function formatDate(millis) {

  const date = new Date(+millis);
  return `${months[date.getMonth()]} ${date.getFullYear()}`;
}

// Get millis from text
export function timestamp(str) {

  return new Date(str).getTime();
}

export async function sleep(ms) {

  return new Promise((resolve) => setTimeout(resolve, ms));
}

// Request sugar
export function objToQueryString(obj) {

  const keyValuePairs = [];
  for (let i = 0; i < Object.keys(obj).length; i += 1) {
    keyValuePairs.push(
      `${encodeURIComponent(Object.keys(obj)[i])}=${encodeURIComponent(
        Object.values(obj)[i]
      )}`
    );
  }
  return keyValuePairs.join("&");
}

export function decodeJWT(token, where) {
  if (!token) return console.error("no token", where)
  console.log(where, "decodeJWT", token?.slice(0, 20))
  try {
    return jwt_decode(token);
  } catch (e) {
    console.error(e, "decodeJWT", where)
  }
  return null;
}

export function getJWT() {

  if (!(typeof window !== 'undefined')) { console.error("getJWT must be called by user browser, not JS server."); return }
  return localStorage.getItem("ethi_access");
}

export function getRefresh() {

  // TODO secure this properly
  return localStorage.getItem("ethi_refresh");
}

export function deleteJWT() {

  let ret = "";
  let token = localStorage.getItem("ethi_access");
  if (token && token != "") {
    let decodedToken = decodeJWT(token, "deleteJWT110");
    ret = decodedToken.name;
    setJWT("");
  }
  return ret;
}

export function setJWT(token) {

  localStorage.setItem("ethi_access", token);
}

export function setRefresh(token) {

  if (token?.length < 50) {
    console.error("refresh token isn't good, won't store", token);
    return;
  }
  localStorage.setItem("ethi_refresh", token);
}

export function getHeaders(upload) {

  const token = getJWT();
  let headers = {
    "Access-Control-Allow-Headers": ["AI-Calls-Remaining", "AI-Call-Limit"],
    "Access-Control-Expose-Headers": ["AI-Calls-Remaining", "AI-Call-Limit"],
  };

  if (token != "") {
    headers["Authorization"] = `Bearer ${token}`;
  }
  if (!upload) {
    headers["Content-Type"] = "application/json";
  }
  return headers;
}
let lastRefreshed = 0;
if (typeof window !== "undefined") window.refresh = refresh
export async function refresh(retry) {

  if (!(typeof window !== 'undefined')) { console.error("Refresh must be called by user browser, not JS server."); return }
  if (!get(UserEmail)) return;
  if (currentTime() - lastRefreshed < 30) return;
  lastRefreshed = currentTime();

  let refreshToken = getRefresh();
  if (window.firebaseAuth?.currentUser) return window.firebaseAuth?.currentUser
  let firebaseID = window.firebaseAuth?.currentUser?.getIdToken(true)
  let firebaseInterval
  if (!firebaseID) {
    window.firebasePromise = Promise.race([
      new Promise(resolve => setTimeout(resolve, 10000)),
      new Promise(resolve =>
        firebaseInterval = setInterval(async () => {

          if (window.firebaseAuth?.currentUser) {
            let user = window.firebaseAuth?.currentUser
            if (!user) return
            let token = {
              email: user?.email,
              emailVerified: user.emailVerified,
              metadata: user.metadata,
              token: user.accessToken,
              uid: user.uid,
            }
            if (token)
              UserToken.set(token)
            localStorage.userToken = JSON.stringify(token)
            let firebaseID = await window.firebaseAuth?.currentUser?.getIdToken(true)
            clearInterval(firebaseInterval)
            resolve(firebaseID)
          }
        }, 1000)
      )])
    firebaseID = await window.firebasePromise
  }
  console.log(
    "refreshing firebase",
    firebaseID
  )
  if (omnipilot || window.location.href.includes('omnipilot')) {
    console.log("killing magicflow refresh");
    return firebaseID;
  }
  if (!refreshToken) {
    console.error("No refresh token found, must be logged in to refresh.");
    if (get(IsDev)) return;
    if (!window.location.href.includes('/action') && get(UserEmail))
      goto("/logout");
    return
  };
  if (refreshToken?.length < 50) {
    console.error("refresh token isn't good, logging out", refreshToken);
    if (!window.location.href.includes('/action') && get(UserEmail))
      goto("/logout");
    return;
  }
  let tokenDetails = decodeJWT(refreshToken, "utils208");

  if (!tokenDetails || tokenDetails?.exp < currentTime()) {
    console.error("Refresh token is out of date, logging out", tokenDetails);
    if (!window.location.href.includes('/action') && get(UserEmail))
      goto("/logout");
    return;
  }
  if (!window.ethiRefresh) window.ethiRefresh = fetch(`${serverURL}/api/v1/user/refresh/`, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  }).then(async function (res) {
    let json;
    if (res.status == 200) {
      let data = await res.json();
      setJWT(data.access_token);
      if (data.access_token) {
        let tokenInfo = decodeJWT(data.access_token, "utils228");
        if (tokenInfo?.exp < currentTime()) {
          console.error("New access token is also out of date, logging out", { tokenInfo, data });
          goto("/logout");
        }
      }
      if (data.refresh_token) setRefresh(data.refresh_token);
      return data.access_token;
    } else {
      json = await res.json()
      console.error(
        `Error, status: ${res.status}, ` + JSON.stringify(json)
      );
    }
    if (get(UserEmail) && (res.status === 404 || res.status === 401)) {
      console.error("Refresh response unauthorised ", { res, data: json || await res.json() });
      goto("/logout");
      return
    } else if ((retry || 0) + 1 < 3) {
      retry = (retry || 0) + 1;
      return await refresh(retry)
    }
  }).then(r => { setTimeout(() => delete window.ethiRefresh, 5000); return r }).catch(e => {
    console.error("Magicflow refresh function didn't complete: ", e, JSON.stringify(e));
    setTimeout(() => delete window.ethiRefresh, 5000);
  });

  return await window.ethiRefresh
}

async function request_sugar(type, endpoint, body, upload, isRetry) {

  if (!(typeof window !== 'undefined'))
    throw (
      ("request_sugar must be called by user browser, not JS server.",
        type,
        endpoint)
    );
  if (omnipilot) {
    console.log("killing magicflow request");
    return;
  }
  endpoint =
    endpoint.startsWith("http") || endpoint.startsWith("localhost")
      ? endpoint
      : serverURL + endpoint;
  let jwt = getJWT()
  if (jwt) {
    let user = decodeJWT(jwt, "utils284");


    const expiry = user?.exp || (await refresh())?.exp;
    if (!expiry && !window.location.href.includes('omnipilot')) {
      console.log(jwt, user?.exp)
      return
    }
    if (expiry && currentTime() > expiry) {
      await refresh();

    }
  }
  let fetchOptions = {
    method: type,
    credentials: "include",
    body: body,
    headers: getHeaders(upload),
  };

  let promises = get(Promises)
  body = body || false
  let existingPromise = promises[type + endpoint + upload + JSON.stringify(body).length + JSON.stringify(body).slice(body?.length - 10) + JSON.stringify(body).slice(0, body?.length - 10)]

  let promise = existingPromise || fetch(endpoint, fetchOptions)
    .then(async function (res) {
      if (get(IsDev) && res.status !== 200 && res.status !== 201 && res.status !== 204) console.log("Response ", { url: res.url, status: res.status, statusText: res.statusText }, "req sug");
      if (res.status === 500) {
        console.error(endpoint, "Server error! Something went wrong.");
        return res;
      }
      else if (
        res.status === 401 && !(res.url &&
          res.url.includes("spotify") || res.url.includes("twitter") || res.url.includes("google") ||
          res.url.includes("login"))
      ) {
        console.log("Token needs refreshing for ", endpoint)
        let response = await res.json();
        if (response && response.data && response.data.includes("verify your email")) return response.data;
        let accessToken = await refresh();
        if (accessToken && !isRetry)
          return await new Promise((resolve) =>
            setTimeout(
              () => resolve(request_sugar(type, endpoint, body, upload, "isRetry")),
              4000
            )
          );
      }
      else if (res.status === 401 || res.status === 500) {
        console.error(endpoint, res.status, res.statusText || "You do not have the required data uploaded.");
        return res.statusText;
      } else if (res.status === 204) {
        return
      }
      else if (
        res.status.toString().startsWith("4") ||
        res.status.toString().startsWith("5")
      ) {
        let response = await res.json();
        throw new Error(JSON.stringify(response.data || response));
      } else {
        // console.log("Response good ", res);
        let text = res && await res.text();
        if (text.slice(0, 1000).includes("NaN")) console.log(text.slice(0, 1000))
        text = text.replace(/: NaN/g, ": 0");
        if (text.slice(0, 1000).includes("NaN")) console.log(text.slice(0, 1000))
        try {
          return JSON.parse(text);
        } catch (e) {
          console.error(e)
        }
      }
    })
    .catch(async (err) => {
      if (err.message && err.message.includes("Failed to fetch") && upload) {
        return new Promise((resolve) =>
          setTimeout(
            () => resolve(request_sugar(type, endpoint, body, upload)),
            4000
          )
        );
      } else {
        if (err?.message.includes("not found") && err?.message.includes('User ') && !window.location.href.includes('omnipilot')) {
          console.error("User not found, logging out");
          goto("/logout");
          return;
        }
        console.error(err, "request sugar error", endpoint, err?.message);
        let accessToken = await refresh();
        if (accessToken && !isRetry)
          return await new Promise((resolve) =>
            setTimeout(
              () => resolve(request_sugar(type, endpoint, body, upload, "isRetry")),
              4000
            )
          );
      }
      // return err;
    });
  if (!existingPromise) {
    promises[type + endpoint + upload + JSON.stringify(body).length + JSON.stringify(body).slice(body?.length - 10) + JSON.stringify(body).slice(0, body?.length - 10)] = promise;
    promise.then(r => {
      let promises = get(Promises);
      delete promises[type + endpoint + upload + JSON.stringify(body).length + JSON.stringify(body).slice(body?.length - 10) + JSON.stringify(body).slice(0, body?.length - 10)];
      Promises.set(promises);
    })
    Promises.set(promises);
  };
  let result = await promise;
  if (typeof result === "string") console.error("Error:", { result, endpoint });
  return result;
}

// Get request sugar
export async function get_sugar(endpoint, params) {

  if (!get(UserEmail)) await sleep(3000);
  if (!get(UserEmail)) return;
  if (params) {
    let keyValuePairs = objToQueryString(params);
    endpoint = `${endpoint}?${keyValuePairs}`;
  }
  return request_sugar("GET", endpoint, null, false).catch((res) => {
    console.error(res)
      ;
  });
}
typeof window !== "undefined" && (window.get_sugar = get_sugar);

export function delete_sugar(endpoint, params) {

  if (params) {
    let keyValuePairs = objToQueryString(params);
    endpoint = `${endpoint}?${keyValuePairs}`;
  }
  return request_sugar("DELETE", endpoint);
}

// Post request sugar
export function post_sugar(endpoint, data, params, upload, signal) {

  if (params) {
    let keyValuePairs = objToQueryString(params);
    endpoint = `${endpoint}?${keyValuePairs}`;
  }
  let postBody;
  if (upload) {
    postBody = new FormData();
    Object.entries(data || {}).forEach(([key, value]) => {
      if (key === "metadata") postBody.append(key, JSON.stringify(value));
      else postBody.append(key, value);
    });
  } else {
    postBody = JSON.stringify(data);
  }

  return request_sugar("POST", endpoint, postBody, upload, signal);
}

// Data helpers
export async function uploadData(data, params) {

  if (serverURL === "noauth" || !get(UserEmail)) return false;
  return await post_sugar(
    `${serverURL}/api/v1/data/upload/`,
    data,
    params,
    true
  );
}


export async function getGraph(
  graphName,
  noLocalCache,
  skipCache,
  noRemoteCache
) {

  if (!(typeof window !== 'undefined')) {
    console.error(
      "getGraph must be called by user browser, not JS server.",
      graphName
    ); return
  }
  let local = await checkLocal(graphName)
  if (local && !noLocalCache && !skipCache) return local;
  const params = {
    name: graphName,
  };
  if (skipCache) params.skip_cache = skipCache;
  if (noRemoteCache) params.no_cache = noRemoteCache;
  const res = await get_sugar(`/api/v1/data/graph/`, params);

  const data = res;
  if (!res) {
    console.error("getGraph", graphName, res);
    return;
  };
  if (!noLocalCache && data && typeof data == 'string' && data.toUpperCase() !== "NO CONTENT") storeLocal(graphName, data);
  return data;
}

typeof window !== "undefined" ? window.getting = {} : ""

export function checkLocal(dataName) {
  console.log("getting ", dataName)
  if (typeof window === "undefined") {
    return;
  }
  if (typeof window?.getting === "undefined") {
    window.getting = {}
    return
  }
  if (window.getting?.[dataName]) return;

  console.log("checking local ", dataName)
  window.getting[dataName] = true;
  setTimeout(() => { window.getting[dataName] = false; delete window.getting?.[dataName] }, 12000)
  let localCache = localStorage.getItem(dataName);
  if (!localCache) return;
  else if (localCache.includes('NO CONTENT')) {
    delete localStorage[dataName];
    return
  }
  if (localCache.startsWith("{") || localCache.startsWith("[")) try {
    let cache = JSON.parse(localCache)
    storeLocal(dataName, cache, "storeIfCompressingShrinks")
    return cache;
  } catch (e) {
    console.error(e, "checkLocal")
  }
  if (dataName === "timeseries") { localStorage.removeItem(dataName); return false };
  try {
    let uint = base64ToBytes(localCache)
    let inflatedCache = JSON.parse(pako.inflate(uint, { to: 'string' }));
    return inflatedCache;
  }
  catch (e) {
    console.error(e, "checkLocal")
  }

}
if (typeof window !== "undefined") window.checkLocal = checkLocal
if (typeof window !== "undefined") window.storeLocal = storeLocal

let storing = {

}
export function storeLocal(dataName, data, alreadyStored) {
  if (typeof window === 'undefined') {
    console.error(
      "storeLocal must be called by user browser, not JS server.",
      dataName
    ); return
  }
  if (storing[dataName]) return;
  console.log("storing ", dataName)
  storing[dataName] = true;
  let dataString = JSON.stringify(data);
  if (!data || !dataString || dataString.includes('NO CONTENT')) return;
  let deflatedString = bytesToBase64(pako.deflate(dataString));
  if (alreadyStored && dataString?.length <= deflatedString?.length) return;
  try {
    localStorage[dataName] =
      // dataString?.length <= deflatedString?.length ? dataString : 
      deflatedString;
  } catch (e) {
    console.error(e, "storeLocal")
  }
  setTimeout(() => delete storing[dataName], 10000)
}

export async function getData(dataName, source, noCache, extraParams, noStoring) {
  if (typeof window === 'undefined') {
    console.error(
      "getData must be called by user browser, not JS server.",
      dataName, source
    ); return
  }
  source = source || "facebook";
  // console.log(({ dataName, source, noCache, extraParams, noStoring }))
  !dataName.includes("timeseries") ? source = "single/" + source : ""
  let dataStore = get(Data);
  let local;
  let data;
  let storedData = dataStore[JSON.stringify([dataName, source, noCache])]
  if (!noStoring && !storedData && typeof window !== "undefined") local = await checkLocal(JSON.stringify([dataName, source, noCache]));
  else if (!noCache && !noStoring) {
    return storedData;
  }
  if (local && !noStoring) {
    dataStore[JSON.stringify([dataName, source, noCache])] = local;
    console.log("updating data from getData1")

    Data.set(dataStore)
  }
  const params = {
    name: dataName,
    ...(extraParams || {})
  };
  if (local && !noCache && !noStoring) data = local;
  else data = await get_sugar(`/api/v1/data/query/${source}/`, params).then(async res => {
    const data = res && await res;
    if (!data || typeof data === "string") {
      return
    }
    return await data;
  });
  if (data && noStoring) { console.log("not setting Data, returning"); return data; }
  if (!data) {
    let dataStore = get(Data);
    let current = dataStore[JSON.stringify([dataName, source, noCache])];
    let local = checkLocal(JSON.stringify([dataName, source, noCache]));

    if (!current && local && JSON.stringify(dataStore[JSON.stringify([dataName, source, noCache])]).length !== JSON.stringify(local).length) {
      dataStore[JSON.stringify([dataName, source, noCache])] = local;
      console.log("updating data from getData2")
      Data.set(dataStore)
      return local;
    } else return current;
  }

  if (dataName.startsWith("timeseries")) {
    if (data?.length && data.filter((a) => a).length) data = data.filter((a) => a)
    else if (!Object.values(data || {}).length) return;
    let day = {}
    let date;
    if (/\/\d+.+/.test(source)) {
      date = source.match((/\/\d+.+/))[0].replace("/", "");
      source = source.replace(/\/\d+.+/, ""); // get rid of trailing date, leaving just timeseries/source, for data sharing
      day[date] = data
    }

    // merge this data with the existing set of timeseries data from that source
    let newData = {
      ...(dataStore[`["timeseries","${source}","noCache"]`] || {}),
      ...(date ? day : data),
      lastUpdated: dayjs().valueOf()
    }
    if (JSON.stringify(newData)?.length === JSON.stringify(dataStore[`["timeseries","${source}","noCache"]`])?.length) return
    dataStore[`["timeseries","${source}","noCache"]`] = {
      ...(dataStore[`["timeseries","${source}","noCache"]`] || {}),
      ...(date ? day : data),
      lastUpdated: dayjs().valueOf()
    };
    // cache it locally
    storeLocal(`["timeseries","${source}","noCache"]`, dataStore[`["timeseries","${source}","noCache"]`])
  } else {
    dataStore[JSON.stringify([dataName, source, noCache])] = data;
    storeLocal(JSON.stringify([dataName, source, noCache]), await data)
  }
  let prevData = get(Data);
  if (JSON.stringify(prevData).length === JSON.stringify(dataStore).length) return
  console.log("updating data from getData3")
  Data.set(dataStore);
  return data;
}
async function prepareForUpload(rawData, path) {

  if (!rawData) return console.error("no raw data??", { path, rawData });
  let buffer = new TextEncoder().encode(JSON.stringify(rawData));
  let data = b64(pako.gzip(buffer));

  return {
    metadata: { length: JSON.stringify(rawData).length },
    data: data,
    key: path,
    time: currentTime(),
  };
}
export async function uploadTimeSeries(rawData, source, daysInPast) {

  daysInPast = daysInPast || 0;

  let dataToUpload = await prepareForUpload(rawData, source === "apple" ? source : source + ".json");
  if (!dataToUpload) return console.error("no timeSeries data??", { source, daysInPast });
  uploadData(dataToUpload, {
    source,
    strategy: "time_series",
    transaction: "create",
    date: dayjs().subtract(4, "hour").subtract(daysInPast, "days").format('YYYY-MM-DD'),
  });
}

export async function uploadNoPipeline(path, source, rawData) {
  let dataToUpload = await prepareForUpload(rawData, path);
  if (!dataToUpload) return console.error("no noPipeline data??", { source, path });
  uploadData(dataToUpload, { source });
}


export async function getTimeSeries(source, options) {

  options.range = options.range ? Object.fromEntries(Object.entries(options.range || {}).map(date => { date[1] = dayjs(date[1]).format("YYYY-MM-DD"); return date })) : {}
  let data
  if (options.daysInPast !== undefined) {
    let date = dayjs().subtract(4, "hour").subtract(options.daysInPast, "day").format("YYYY-MM-DD")
    data = await getData(
      "timeseries" + date.replace(/-/g, "_"),
      `time_series/${source}/` + date,
      "noCache",
      undefined,
      options.noStoring
    ).catch((error) => console.error(error));
  }
  else {
    data = await getData(
      "timeseries",
      `time_series/${source}`,
      "noCache",
      { ...(options.range || []) },
      options.noStoring
    ).catch((error) => console.error(error));
  }
  if (data && data?.length) data = data.filter((a) => a)
  return data;
}
typeof window !== "undefined" ? window.getTimeSeries = getTimeSeries : ""

export async function getAllMetadata() {

  let meta = await checkLocal("metadata");
  if (meta) {
    MetaData.set(meta);
    return meta;
  }
  let res = await getMetadata();
  if (res && Object.keys(res).length) {
    MetaData.set(res);
    storeLocal("metadata", res);
  }
  return res;
}

export async function getMetadata(params) {

  ;

  return await get_sugar(`${serverURL}/api/v1/data/metadata/`, params)
}

export async function getDataUploaded() {

  let uploadedKeys = await get_sugar(`${serverURL}/api/v1/data/names/`);
  let dataUp;
  if (uploadedKeys && Object.keys(uploadedKeys).length) {
    dataUp = Object.fromEntries(Object.entries(uploadedKeys).map(i => { i[1] = i[1]?.single; return i }));
    let datesUp = Object.fromEntries(Object.entries(uploadedKeys).map(i => { i[1] = i[1]?.time_series; return i }));
    DataUploaded.set(dataUp);
    DatesUploaded.set(datesUp);
    storeLocal("dataUploaded", dataUp);
    storeLocal("datesUploaded", datesUp);
    return dataUp;
  }
  return dataUp;
}

export async function sendFeedback(params) {

  if (serverURL == "noauth") return false;
  return post_sugar(`${serverURL}/api/v1/data/feedback/`, params);
}


export async function deleteData(source, dataToDelete) {

  // this function deletes all data if no parameters are passed

  // this function will delete an entire data source if dataToDelete is not passed

  let params = {
    confirmation: "yes",
  };
  if (dataToDelete) params.name = dataToDelete;;
  if (serverURL == "noauth") return false;
  if (!dataToDelete) clearStores("stayLoggedIn", "keepData");
  return delete_sugar(
    `${serverURL}/api/v1/data/delete${source ? "/" + source : ""}/`,
    params
  );
}

export async function getParameterByName(name, url) {

  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export async function rePreProcess(graphName) {

  if (graphName) localStorage.removeItem(graphName);
  let params = graphName ? { name: graphName } : undefined;
  await get_sugar(`${serverURL}/api/v1/data/preprocess/`, params);
  alert("Repreprocessing graph(s) - this can take 2-3 minutes");
}

export async function getCachedGraphs() {

  return await get_sugar(`${serverURL}/api/v1/data/cached/`).then(
    async (res) => {
      let graphs = res && await res;
      if (!(graphs && typeof graphs === "object" && graphs?.length)) return []
      let cachedArray = graphs
        .filter((graph) => graph[1])
        .map((graph) => graph?.[0]);
      GraphsCached.set(cachedArray);
      return graphs;
    }
  ).catch((error) => console.error(error));
}

export async function getKarma() {

  return await get_sugar(`${serverURL}/api/v1/user/karma/`)
}

export function hasDataUploaded(graphItem, dataUploaded) {

  if (!dataUploaded || !graphItem) return false;
  return graphItem.sources.some((source) => {
    //for at least one source
    if (source == "spotify") return (get(LiveConnections) || {}).profile;
    return graphItem.dataRequirements.some(
      (
        requirement //check that every requirement for that source
      ) =>
        !requirement || requirement == "messages->inbox" && (dataUploaded[source] || []).some(fileName => fileName && fileName.includes("message_1.json")) ||
        (dataUploaded && (dataUploaded[source] || []).includes(requirement))
    ); //is satisfied
  });
}


export function dateStupidSafari(date) {

  if (!date) return;
  if (typeof date !== "string") return new Date(date);
  let [dayDate, time] = date.split("T");
  let [year, month, day] = dayDate.split("-")
  return new Date(`${month}/${day}/${year} ${time.split("-")[0]}`);
}

let postUserInfoPromise
export async function postUserInfo() {

  let userInfo = get(UserInfo);
  let cache = checkLocal('userInfo')
  if (postUserInfoPromise)
    await postUserInfoPromise;
  let serverUserInfo = await getUserInfo("post");
  if (!serverUserInfo) {
    console.error("can't post user info as getting server userinfo failing")
    setTimeout(() => postUserInfo(), 20000)
    return;
  }
  // console.log({ serverUserInfo, cache, userInfo })
  let mergedUserInfoWithLocalSourceOfTruthPrioritised = (serverUserInfo || cache || userInfo) ? { ...(serverUserInfo || {}), ...(cache || {}), ...(userInfo || {}), lastUpdated: dayjs().format() } : {};
  mergedUserInfoWithLocalSourceOfTruthPrioritised = Object.fromEntries(Object.entries(mergedUserInfoWithLocalSourceOfTruthPrioritised).filter(i => !(typeof +i[0] === 'number' && !isNaN(+i[0]))))
  userInfo = mergedUserInfoWithLocalSourceOfTruthPrioritised;
  console.log({ mergedUserInfoWithLocalSourceOfTruthPrioritised })
  UserInfo.set(userInfo)

  if (userInfo && typeof userInfo === "object" && typeof window !== "undefined") storeLocal('userInfo', userInfo);
  postUserInfoPromise = get(Version).app == 'omnipilot' ? writeFirebaseDoc({
    collection: 'userInfo',
    docPath: []
  }, userInfo) : post_sugar('/api/v1/user/session/', userInfo).catch(r => console.error(r));
  return await postUserInfoPromise;
}
if (typeof window !== "undefined") {
  window.postUserInfo = postUserInfo
  window.getUserInfo = getUserInfo
}

let userInfoPromise
export async function getUserInfo(where) {

  // console.log(where)
  let error;
  userInfoPromise = (userInfoPromise || (get(Version).app == 'omnipilot' ? readFirebaseDoc({
    collection: 'userInfo',
    docPath: []
  }).catch(e => console.error(e)) : get_sugar('/api/v1/user/session/')))

  let cache = checkLocal('userInfo');
  let current = get(UserInfo);
  if (cache && !Object.entries(current || {}).length && Object.entries(cache).length) UserInfo.set(cache);
  let userInfo = await userInfoPromise.catch(r => (error = true) && console.error(r));;
  userInfoPromise = undefined;
  if (error && where == "post") return;

  let serverUserInfo = userInfo && userInfo.data || {};
  // console.log({ serverUserInfo, cache, current })
  let mergedUserInfoWithLocalSourceOfTruthPrioritised = (serverUserInfo || cache || current) ? { ...(serverUserInfo || {}), ...(cache || {}), ...(current || {}) } : {}
  mergedUserInfoWithLocalSourceOfTruthPrioritised = Object.fromEntries(Object.entries(mergedUserInfoWithLocalSourceOfTruthPrioritised).filter(i => !(typeof +i[0] === 'number' && !isNaN(+i[0]))))

  UserInfo.set(mergedUserInfoWithLocalSourceOfTruthPrioritised);
  if (error) return
  return mergedUserInfoWithLocalSourceOfTruthPrioritised
}
export function stripTimeZone(ISO8601DateString) {

  if (!ISO8601DateString) {
    console.error("stripTimeZone", { ISO8601DateString })
    return
  }
  return ISO8601DateString.replace(/([-+]\d\d:\d\d)|Z/, "")
}


export function checkUnique(value, index, self) {

  return self.indexOf(value) === index;
}
export function averageOverRange(numericalArray, start, end, decimalPlaces) {

  if (!(numericalArray && numericalArray?.length)) return;
  decimalPlaces = decimalPlaces || 0;
  start = start || 0;
  end = end || numericalArray?.length;
  let average =
    numericalArray.slice(start, end).reduce((a, b) => a + b, 0) / (end - start);
  return (
    Math.round(Math.pow(10, decimalPlaces) * average) /
    Math.pow(10, decimalPlaces)
  );
}
export function aggregateObject(aggregator, object, keys, property) {

  object = { ...object }
  keys.forEach(key => {
    let uniqueIndex = object[key];
    if (aggregator[uniqueIndex])
      aggregator[uniqueIndex][property] += (object[property]);
    else {
      aggregator[uniqueIndex] = { ...(object || []) }
    }
  })
  return aggregator
}
let browsers = [
  'Google Chrome',
  'Safari',
  'Brave',
  'Gener8',
  'sigma',
  'sidekick',
  'firefox',
  'opera',
  'vivaldi',
  'mighty',
  'edge',
  'internet explorer',
  'browser',
  'arc'
];
export function aggregateAndSort(events, byCategory, average) {
  if (!events?.length) return [];
  let aggregatedEvents = events?.filter((e) => typeof e === 'object').map((e) => {
    let duration = average ? e.duration / events.filter(event => event.categories?.[0] === e.categories?.[0]).length : e.duration
    let keyToAggregateOn;
    if (byCategory) {
      keyToAggregateOn = (e.data || e).categories && (e.data || e).categories?.[0]
    } else {
      let isBrowserEvent = browsers.some(
        (browser) =>
          (e.data || e)?.app.toLowerCase().includes(browser.toLowerCase())
      );
      let isExtension = (e.url)?.includes('-extension');
      let eventType, eventString;
      if (isBrowserEvent) {
        let domain = ((e.data || e).url?.split('/')[/(http|file)(s)*:\/\//.test((e.data || e).url) ? 2 : 0]?.replace('www.', '') ||
          `${(e.data || e).title || (e.data || e).categories?.[0]} - ${(e.data || e)?.app}`) || (e.data || e).categories?.[0] + " - " + e.app;
        eventType = isExtension ? 'Extension' : 'Site';
        eventString = isExtension ? e.title || e.categories?.[0] : domain;
      } else {
        eventType = 'App';
        eventString = (e.data || e).app || (e.data || e).path?.split('/').pop();
      }
      keyToAggregateOn = `${eventType}: ${eventString};${(e.data || e).categories && (e.data || e).categories?.[0]}`
    }
    return {
      ...(e || []),
      duration,
      keyToAggregateOn
    }
  }).reverse()
    .reduce((agg, event) => aggregateObject(agg, event, ['keyToAggregateOn'], 'duration'), {}) || {}

  return (
    Object.entries(
      aggregatedEvents
    ).sort((a, b) => b[1].duration - a[1].duration).map(([type, data]) => ([
      type?.split(';')[0], data
    ]))
  );
}

function humanTime(time) {
  let secondsSince = dayjs().diff(time, 'second');
  if (secondsSince > 69 * 60) return time.format('HH:mm');
  else if (secondsSince > 60 * 60) return '1 hour';
  else if (secondsSince > 2 * 60) return Math.floor(secondsSince / 60) + ' minutes';
  else if (secondsSince > 10) return secondsSince + ' seconds';
  else return 'Now';
}

// delay function delays the execution of a function by a given time
export function delay(func, wait, ...args) {
  return () => setTimeout(func, wait, ...args);
}

export async function chat(prompt, history, body, options) {
  history = history || [
    {
      role: 'system',
      content:
        "Be ultra concise, and don't say any boilerplate AI-isms like 'as a language model'"
    }
  ]
  let claims = get(EthiClaims);
  let token = get(UserToken)
  let uid = (claims?.identity || claims?.uid || token.uid)
  if (!uid && !get(IsDev)) { console.error("No uid found", claims, token); return }
  if (options?.passThrough || options?.openAIKey && (options?.model || body?.model).includes('gpt')) {
    body = body || {
      model: options.model || 'gpt-4o-mini',
      messages: [
        ...history,
        {
          role: "user",
          content: prompt
        }
      ]
    }
    return fetch(`${options.openAIKey && (options.model || body.model).includes('gpt') ? "" : 'https://cors.magicflow.workers.dev/?'}https://api.openai.com/v1/chat/completions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${options.openAIKey || localStorage.ethi_access}`
      },
      body: JSON.stringify(
        body
      )
    }).then(r => r.json())
  }
  return fetch(`https://ai.magicflow.workers.dev/?user=mike&chat=true&uid=${(get(IsDev) ? 'dev' : '') + uid}${Object.entries(body || {}).map(([key, value]) => `&${key}=${value}`).join('')
    }`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.ethi_access}`
    },
    body: JSON.stringify(
      [
        ...history,
        {
          role: "user",
          dayjs: dayjs(),
          content: prompt
        }
      ].map((m) => {
        return {
          "role": m.role,
          "content": m.content
        };
      })
    )
  }).then(r => r.json())
}
typeof window !== 'undefined' ? window.chat = chat : null

// import { Message, OpenAIModel } from "@/types";
import { createParser } from "eventsource-parser";

export const AIStream = async (messages, copilotSettings, model, isRetry) => {
  const encoder = new TextEncoder();
  const decoder = new TextDecoder();
  let claims = get(EthiClaims);
  let token = get(UserToken)
  let uid = (claims?.identity || claims?.uid || token.uid)
  // const res = await fetch("https://api.openai.com/v1/chat/completions", {
  let system;
  if (messages?.[0]?.role == "system" && model.includes('claude')) {
    system = messages[0].content;
    messages = messages.slice(1);
  }
  let body = model.includes('gemini') ? {
    "contents": messages.map(m => (
      {
        role: m.role == "assistant" ? "model" : "user",
        parts: typeof m.content == "string" ? [{ text: m.content }] : m.content,
      }))
  } : {
    model,
    messages: [
      ...messages
    ],
    ...(system ? { system } : {}),
    max_tokens: 3200,
    temperature: 0.5,
    stream: true,
    user: (get(IsDev) ? 'dev' : '') + uid,
  }
  if (model.includes('claude')) {
    delete body.user
  }
  let urls = {
    claude: "https://api.anthropic.com/v1/messages",
    gemini: "https://generativelanguage.googleapis.com/v1beta/models/" + model + ":streamGenerateContent",
    openai: "https://api.openai.com/v1/chat/completions",
    perplexity: "https://api.perplexity.ai/chat/completions"
  }
  let modelFamily = {
    "llama-3-sonar-small-32k-online": "perplexity",
    "llama-3-sonar-large-32k-online": "perplexity",
  }
  let family = modelFamily[model] || (copilotSettings.openAIKey && (model).includes('gpt') ? "openai" : "") || model.split('-')[0]
  let baseURL = urls[family]
  /*
  fetch('https://cors.magicflow.workers.dev/?https://generativelanguage.googleapis.com/v1/models/gemini-1.5-flash:streamGenerateContent', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify()
})
.then(response => response.json())
.then(data => console.log(data))
.catch(error => console.error('Error:', error));
*/
  const res = await fetch(baseURL ? (family == "openai" && copilotSettings.openAIKey ? "" : "https://cors.magicflow.workers.dev/?") + baseURL : `https://ai.magicflow.workers.dev/?user=mike&chat=true&streaming=true&uid=${(get(IsDev) ? 'dev' : '') + uid}`, {
    method: "POST",
    headers: { 'Authorization': `Bearer ${family == "openai" && copilotSettings.openAIKey || ("sk-" + localStorage.ethi_access) || "sk-4kk888ahlghwjtkelfxBHCKbFT3Blb439lxLRd3edVrRnyoHP"}`, "content-type": "application/json" },
    body: JSON.stringify(body)
  });
  //   [
  //     "model": stream ? "claude-3-opus-20240229"  : "claude-3-sonnet-20240229", // "claude-3-opus-20240229"
  //     "stream": stream,
  //     "max_tokens": options?["max_tokens"] ?? 200,
  //     "system": defaultHistory[0]["content"],
  //     "messages": Array(defaultHistory.dropFirst()) + [newMessage],
  //     "temperature": options?["temperature"] ?? 0.4,
  //     "stop_sequences": stream ? [] : [";", ". " ]

  // ]
  //   {
  //     "error": {
  //         "message": "That model is currently overloaded with other requests. You can retry your request, or contact us through our help center at help.openai.com if the error persists. (Please include the request ID 205be89374360f47ecc3bf1e5d6a9e73 in your message.)",
  //         "type": "server_error",
  //         "param": null,
  //         "code": null
  //     }
  // }
  if (res?.status !== 200 || res?.error || !res) {
    if (!isRetry) return await AIStream(messages, copilotSettings, model, true);
    else
      throw new Error("OpenAI API returned an error");
  }

  const stream = new ReadableStream({
    async start(controller) {
      const onParse = (event) => {
        // console.log(event)
        if (event.type === "event") {
          const data = event.data;

          if (data === "[DONE]" || data?.includes("message_stop")) {
            console.log('Received', { data })

            return;
          }

          try {
            const json = JSON.parse(data);
            const text = json.choices?.[0]?.delta?.content || json["completion"] || json["text"] || json.delta?.["completion"] || json.delta?.["text"] || json.candidates?.[0]?.content?.parts?.[0]?.text;
            if (!text) {
              console.log('Received no text', { json })
              let errorFinishReasons = {
                "SAFETY": "The response candidate content was flagged for safety reasons.",
                "RECITATION": "The response candidate content was flagged for recitation reasons.",
                "LANGUAGE": "The response candidate content was flagged for using an unsupported language.",
                "OTHER": "Unknown reason.",
                "MAX_TOKENS": "The maximum number of tokens as specified in the request was reached.",
                "length": "The maximum number of tokens was reached.",
                "BLOCKLIST": "Token generation stopped because the content contains forbidden terms.",
                "PROHIBITED_CONTENT": "Token generation stopped for potentially containing prohibited content.",
                "SPII": "Token generation stopped because the content potentially contains Sensitive Personally Identifiable Information (SPII).",
                "MALFORMED_FUNCTION_CALL": "The function call generated by the model is invalid.",
                "content_filter": "Omitted content due to a flag from our content filters"
              }
              // {"type":"error","error":{"details":null,"type":"overloaded_error","message":"Overloaded"}    }
              let finishReason = json.candidates?.[0]?.finishReason || json.choices?.[0]?.finish_reason
              if (json.type == "error" || errorFinishReasons[finishReason]) {
                const queue = encoder.encode("\n\nError: The external API is not working, generation stopped with reason: " + (json.error?.message || errorFinishReasons[finishReason] || finishReason));
                controller.enqueue(queue);
                return
              }
            }
            const queue = encoder.encode(text);
            controller.enqueue(queue);
          } catch (e) {
            console.error(e)
            controller?.error(e);
          }
        } else {
          console.log('Received, not an event?', { event })
        }
      };

      const parser = createParser(onParse);
      const reader = res.body.getReader();

      while (true) {
        const { value, done } = await reader.read();
        if (done || !value) break;
        let text = decoder.decode(value);
        console.log('Received', { text });

        parser.feed(text);

      }

      console.log('Response fully received');

      const queue = encoder.encode("");
      controller.enqueue(queue);
      controller.close();



      // emit that it's done

      // for (const chunk of res.body) {
      //   let text = decoder.decode(await chunk);
      // }
    }
  });

  return stream;
};

// import { Message } from "@/types";
// import { OpenAIStream } from "@/utils";

// export const config = {
//   runtime: "edge"
// };

const handler = async (reqJSON, model) => {
  try {
    const { messages, copilotSettings } = reqJSON;

    const charLimit = 4 * 12000;
    let charCount = 0;
    let messagesToSend = [];

    for (let i = messages.length - 1; i >= 0; i--) {
      const message = messages[i];
      if (message?.content?.length && (charCount + message.content.length > charLimit)) {
        let limitLeft = charLimit - charCount;
        message.content = message.content.slice(message.content.length - limitLeft, message.content.length);
        charCount += message.content.length;
        messagesToSend.unshift({ role: message.role, content: message.content });
        console.log("message too long, curtailed to:", messagesToSend)
        break;
      }
      charCount += message.content.length;
      messagesToSend.unshift({ role: message.role, content: message.content });
    }

    const stream = await AIStream(messagesToSend, copilotSettings, model);
    let res = new Response(stream, { ok: true });
    // res.ok = true;
    return res
  } catch (error) {
    console.error("HANDLER ERROR")
    console.error(error);
    return new Response("Error", { status: 500 });
  }
};


export async function readStream(response, chatDetails, model, setMessages) {
  return new Promise(async (resolve, reject) => {
    response = response || await handler(chatDetails, model);
    // console.log(response, response.body)

    if (!response?.ok || !response?.body) {
      throw new Error("Stream error", response.statusText);
    }
    const data = response.body;

    if (!data) {
      return;
    }



    const reader = data.getReader();
    const decoder = new TextDecoder();
    let done = false;
    let isFirst = true;

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      const chunkValue = decoder.decode(value);
      console.log(chunkValue, done, doneReading)
      done = doneReading;
      if (isFirst) {
        console.log({ isFirst, chunkValue })
        isFirst = false;
        chatDetails.messages = [
          ...chatDetails.messages,
          {
            role: "assistant",
            content: chunkValue || ""
          }
        ];
        setMessages(chatDetails.messages, chunkValue);
      } else {
        const lastMessage = chatDetails.messages[chatDetails.messages.length - 1];
        const updatedMessage = {
          ...lastMessage,
          content: lastMessage.content + chunkValue
        };
        console.log(updatedMessage.content);
        chatDetails.messages = [...chatDetails.messages.slice(0, -1), updatedMessage];
        setMessages(chatDetails.messages, chunkValue);
      }
    }
    console.log('Done reading stream!');
    resolve(true)
  })
};
typeof window !== 'undefined' ? window.readStream = readStream : null

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
export async function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  await navigator.clipboard.writeText(text).then(
    function () {
      console.log('Async: Copying to clipboard was successful!');
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
      fallbackCopyTextToClipboard(text);
    }
  );
}